<template>
  <router-view />
</template>

<style lang="scss">
@import "assets/normalize.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.container {
  background-color: #061437;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  // padding-top: constant(safe-area-inset-top);
  // padding-right: constant(safe-area-inset-right);
  // padding-bottom: constant(safe-area-inset-bottom);
  // padding-left: constant(safe-area-inset-left);
  // padding-top: env(safe-area-inset-top);
  // padding-right: env(safe-area-inset-right);
  // padding-bottom: env(safe-area-inset-bottom);
  // padding-left: env(safe-area-inset-left);
}
</style>
